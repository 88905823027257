var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "550", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-0" },
        [
          _c("v-card-title", { staticClass: "titleStyle" }, [
            _c("div", { staticClass: "pa-4" }, [
              _vm._v(" Histórico de atualização"),
            ]),
            _c(
              "div",
              { staticClass: "pa-4" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { id: "btnCloseX", icon: "", dark: "" },
                    on: {
                      click: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  [
                    _c("v-icon", { staticClass: "ma-0" }, [
                      _vm._v("mdi-close"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "px-4",
              style:
                "height: 420px !important; max-height: 300px !important;background-color: #e7e7fa",
              attrs: { id: "cardScroll2" },
            },
            [
              _vm._l(_vm.history, function (h) {
                return _c(
                  "v-card-text",
                  { key: h.id, staticClass: "h-title pt-4 pb-0 mt-4" },
                  [
                    _c("div", { staticClass: "row h-row mb-0 rounded" }, [
                      _c(
                        "div",
                        { staticClass: "col-7 pa-4" },
                        [
                          _c(
                            "v-subheader",
                            {
                              staticClass: "pa-0",
                              staticStyle: { "max-height": "24px" },
                            },
                            [_vm._v("Atualização realizada por ")]
                          ),
                          _c(
                            "v-subheader",
                            {
                              staticClass: "pa-0",
                              staticStyle: { "max-height": "24px" },
                            },
                            [_c("strong", [_vm._v(_vm._s(h.user))])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-5 pa-4" },
                        [
                          _c(
                            "v-subheader",
                            {
                              staticClass:
                                "mt-0 pa-0 h-line d-flex justify-end",
                              staticStyle: { "max-height": "24px" },
                            },
                            [_vm._v("Data/hora")]
                          ),
                          _c(
                            "v-subheader",
                            {
                              staticClass: "pa-0 d-flex justify-end",
                              staticStyle: { "max-height": "24px" },
                            },
                            [_c("strong", [_vm._v(_vm._s(h.date))])]
                          ),
                        ],
                        1
                      ),
                      h.differences.before.name !== h.differences.after.name
                        ? _c(
                            "div",
                            { staticClass: "col-12 px-4 pt-0 pb-4" },
                            [
                              _c("v-divider", { staticClass: "pb-4" }),
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: { "max-height": "24px" },
                                },
                                [_c("strong", [_vm._v(" Informação alterada")])]
                              ),
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: { "max-height": "24px" },
                                },
                                [_vm._v("Nome")]
                              ),
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: { "max-height": "24px" },
                                },
                                [
                                  _vm._v(" De  "),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.start_and_end(
                                          h.differences.before.name,
                                          20
                                        )
                                      ) + " "
                                    ),
                                  ]),
                                  _vm._v("  para  "),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.start_and_end(
                                          h.differences.after.name,
                                          20
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      h.differences.before.desc !== h.differences.after.desc
                        ? _c(
                            "div",
                            { staticClass: "col-12 px-4 pt-0 pb-4" },
                            [
                              _c("v-divider", { staticClass: "pb-4" }),
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: { "max-height": "24px" },
                                },
                                [_c("strong", [_vm._v(" Informação alterada")])]
                              ),
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: { "max-height": "24px" },
                                },
                                [_vm._v("Descrição")]
                              ),
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: { "max-height": "24px" },
                                },
                                [
                                  _vm._v(" De  "),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.start_and_end(
                                          h.differences.before.desc,
                                          20
                                        )
                                      ) + " "
                                    ),
                                  ]),
                                  _vm._v("  para  "),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.start_and_end(
                                          h.differences.after.desc,
                                          20
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      h.differences.before.start !== h.differences.after.start
                        ? _c(
                            "div",
                            { staticClass: "col-12 px-4 pt-0 pb-4" },
                            [
                              _c("v-divider", { staticClass: "pb-4" }),
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: { "max-height": "24px" },
                                },
                                [_c("strong", [_vm._v(" Informação alterada")])]
                              ),
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: { "max-height": "24px" },
                                },
                                [_vm._v("Início")]
                              ),
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "pa-0",
                                  staticStyle: { "max-height": "24px" },
                                },
                                [
                                  _vm._v(" De  "),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.start_and_end(
                                          h.differences.before.start,
                                          20
                                        )
                                      ) + " "
                                    ),
                                  ]),
                                  _vm._v("  para  "),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.start_and_end(
                                          h.differences.after.start,
                                          20
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      h.notes !== ""
                        ? _c(
                            "div",
                            { staticClass: "col-12 px-4 pt-0 pb-4" },
                            [
                              _c("v-divider", { staticClass: "pb-4" }),
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "pl-0",
                                  staticStyle: { "max-height": "24px" },
                                },
                                [_c("strong", [_vm._v("Justificativa")])]
                              ),
                              _c(
                                "v-subheader",
                                {
                                  staticClass: "pl-0",
                                  staticStyle: {
                                    display: "inline",
                                    "word-wrap": "break-word",
                                    "text-align": "justify",
                                  },
                                },
                                [_vm._v(_vm._s(h.notes))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
              _c("v-card-text", { staticClass: "h-title py-8 h-footer" }, [
                _c("strong", [
                  _vm._v(
                    "Você visualizou todo histórico de alteração desta natureza"
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "v-card-actions",
            { staticClass: "pa-4" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "btn-ag-primary",
                  attrs: { id: "btnClose" },
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v(" Fechar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }